import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Office = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      seo={{
        title: "Office 365",
        href: slug,
        lang: "en",
      }}
      header={{
        background: "linear-gradient( 45deg, red)",
        icons: "#eb3d01",
        navClass: "office-365",
        ogImage: require("../../../assets/img/portfolio/office_365_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/office-365/",
      }}
    >
      <PortfolioHeader name="office_365" height="176" />
      <section className="container-fluid office_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Office 365</h1>
              <ul>
                <li>Website</li>
                <li>Lead campaign</li>
                <li>Advertising banners</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                The product campaign consisted of a number of activities. We
                created a sales page for the promotion of Microsoft Office365
                service. Although the campaign leads were directed to a
                certified Microsoft partner, the branded materials themselves
                had to be approved. At the beginning of our cooperation, we
                received a 90-page brand book on how we should use the logos,
                icons, slogans, colors and many other elements of Microsoft's
                visual identity for the Office 365 business service. Based on
                this, we have prepared the Landing Page. Thanks to the design
                prepared for the use in accordance with the guidelines, we did
                not receive a single correction to the designed Landing Page.It
                was instantly accepted.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid office_section_3">
        <div className="row no-gutters row_1">
          <div className="col-md-6 text">
            <div className="inner">
              <h3>What is a Landing Page?</h3>
              <p>
                The landing page is the first page that the user gets to as a
                result of the company's marketing activities. The landing page
                can be a standalone page (independent from the business page)
                and focus on just one element of the whole offer. Users find the
                landing page most often after clicking on a banner ad, link in
                an e-mail, sponsored link or other advertising link. A landing
                page is a natural extension of the content in the ad. The main
                goal of the Landing Page is to convert, in our case, to acquire
                companies that will download test Office 365 product keys for
                business and test the product for 60 days. A secondary goal was
                to increase the number of users who see Office 365 for business
                promotional videos.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/office_entire_page.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_2">
          <div className="col-md-6 image">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/office_preview.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Why use Landing Pages?</h3>
              <p>
                The landing page has one goal, it has no subpages and directs
                the user to perform the action we have planned. The purpose is
                for the user to leave contact details. When the user leaves its
                data, a certified partner from a given region contacts him in
                order to verify the company data and send the appropriate number
                of keys.
              </p>
              <p>
                The promotional video helped explain the essence of the solution
                offered by Office 365 for companies. The product page created by
                the developer has other goals and is aimed at direct purchase
                without the possibility of testing the product.
              </p>
              <p>
                The campaign turned out very well and the goals related to the
                number of triggered trial keys were achieved.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Promotional campaign</h3>
              <p>
                We also dealt with a promotional campaign for this product in
                the Małopolska voivodeship and Greater Poland regions. We
                directed users to the Landing Page by:
              </p>
              <ul>
                <li>
                  paid ads on the Facebook social network targeted <br></br>
                  at target groups
                </li>
                <li>paid promotion of the Office 365 movie on Youtube</li>
                <li>email marketing to companies and public schools</li>
                <li>banner promotion on industry websites</li>
                <li>promotion of sponsored links in Google AdWords</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 image">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/office_banners.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid office_section_testimonial">
        <div className=" office_section_testimonial__wrapper">
          <p>
            As part of the feedback from Microsoft, we received the following
            testimonial:
            <br />{" "}
            <em>
              "On our part, we are satisfied with the number of generated
              downloads and leads, but now we are waiting for sales, which is
              the main determinant of our success"
            </em>
          </p>
          <p>
            Finalizacją i wdrożeniami zajmował się bezpośrednio certyfikowany
            partner
          </p>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Office;
